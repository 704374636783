import React from "react"
import marked from "marked"
import Moment from "react-moment"
import { IoMdPin, IoMdBriefcase, IoMdCalendar } from "react-icons/io"

import Layout from "../components/layout"
import Seo from "../components/seo"

const JobTemplate = props => {
  const {
    title,
    description,
    location,
    datePosted,
    employmentType,
    cta,
    _createdAt,
  } = props.pageContext
  return (
    <Layout location={props.location}>
      <Seo
        title="Careers | Quandary Consulting Group"
        description="Join our team of technology consultants and integration specialists to help businesses reduce menial tasks. Great work life balance. Remote team. Learn more."
      />
      <section className="container page-content">
        <div className="row page-content py-5">
          <h1 className="mx-auto">{title}</h1>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header bg-white">
                <div className="row text-center">
                  <div className="col-4 py-3 border-right">
                    <IoMdPin className="mr-3" style={{ fontSize: "26px" }} />{" "}
                    {location}
                  </div>
                  <div className="col-4 py-3 border-right">
                    <IoMdBriefcase
                      className="mr-3"
                      style={{ fontSize: "26px" }}
                    />{" "}
                    {employmentType && employmentType}
                  </div>
                  <div className="col-4 py-3">
                    <IoMdCalendar
                      className="mr-3"
                      style={{ fontSize: "26px" }}
                    />{" "}
                    <Moment format="MMM DD, YYYY">
                      {datePosted ? datePosted : _createdAt}
                    </Moment>
                  </div>
                </div>
              </div>
              <div
                className="card-body py-4 px-5"
                dangerouslySetInnerHTML={{ __html: marked(description) }}
              />
              <div className="card-footer bg-white border-0 text-center">
                <a className="btn btn-primary my-4" href={cta}>
                  Apply
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default JobTemplate
